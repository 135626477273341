<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/machinery"
            ><a>Kartoteki</a></router-link
          >
          <router-link tag="li" to="/records/machinery" class="is-active"
            ><a>{{
              dictionary["table_order_devices_type_field_label"]
            }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div v-if="selectADevice" id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <a @click="exit" class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></a>
            <div>
              <h2 class="title level-left">Wybierz</h2>
            </div>
          </div>
          <div class="buttons level-right">
            <router-link
              tag="a"
              class="button is-medium"
              to="/records/machinery/add"
              ><icon name="plus"></icon><span>Dodaj</span></router-link
            >
          </div>
        </div>
        <div v-else id="toptitle" class="level ym-toptitle">
          <h2 class="title level-left">
            {{ dictionary["table_order_devices_type_field_label"] }}
          </h2>
          <div class="buttons level-right">
            <a
              v-if="selectedRowKeys.length > 0"
              @click.prevent="triggerSendModal"
              class="button is-medium"
              ><icon name="mail"></icon>Wyślij powiadomienie
              <span>do wielu...</span></a
            >
            <router-link
              tag="a"
              class="button is-medium is-dark"
              to="/records/machinery/add"
              ><icon name="plus"></icon><span>Dodaj</span></router-link
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="columns is-variable is-6 is-multiline">
      <div class="column is-12">
        <div
          class="ym-searchbox panelbox b-field-flex search-flex"
          style="gap: 8px"
        >
          <div class="b-field" style="width: 100%; position: relative">
            <b-input
              ref="searchInput"
              v-model="searchQuery"
              size="is-medium"
              placeholder="SZUKAJ..."
              style="width: 100%"
            ></b-input>
            <div
              class="field level level-item level-right ym-searchbox-right"
              v-if="this.searchQuery"
            >
              również nieaktywni
              <b-checkbox
                v-model="searchDeleted"
                size="is-medium"
                true-value="1"
                false-value="0"
                @change.native="toggleSearchDeleted"
              >
              </b-checkbox>
            </div>
          </div>
          <button @click="showTableModal = true" class="button is-medium">
            <icon name="settings"></icon>
          </button>
        </div>
        <ve-table
          ref="tableRef"
          border-y
          :columns="columns"
          :table-data="machineries"
          :event-custom-option="eventCustomOption"
          :cell-style-option="cellStyleOption"
          :cellSelectionOption="cellSelectionOption"
          row-key-field-name="id"
          :checkbox-option="checkboxOption"
          :rowStyleOption="rowStyleOption"
          :columnHiddenOption="columnHiddenOption"
          max-height="calc(100vh - 330px)"
          :fixed-header="true"
          :sort-option="sortOption"
        />
        <div
          class="table-pagination"
          v-show="Object.keys(machineries).length > 0 && total >= perPage"
        >
          <ve-pagination
            :total="total"
            :page-index="page"
            :page-size="perPage"
            :page-size-option="[5, 10, 15, 25, 50, 100]"
            @on-page-number-change="onPageChange"
            @on-page-size-change="onPageSizeChange"
          />
        </div>
        <!-- <b-table
          :data="machineries"

          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort">


          <b-table-column field="name" :label="dictionary['table_order_device_name_field_label']" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.name }}</a>
          </b-table-column>
          <b-table-column field="type" :label="dictionary['table_order_device_type_field_label']" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.type }}</a>
          </b-table-column>
          <b-table-column field="serial" :label="dictionary['table_order_device_serial_field_label']" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.serial }}</a>
          </b-table-column>
          <b-table-column field="" label="" width="100" v-slot="props">
            <span v-if="props.row.is_active === true && selectADevice === false" class="button is-small40" @click="machineryRemove(props.row.id)"><div >Usuń</div></span> 
            <router-link v-if="selectADevice === true" tag="a" class="button is-small40" :to="{ name: 'MachineryDetails', params: { id: props.row.id }}">więcej</router-link>
          </b-table-column>
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div class="content" style="justify-content: center;font-weight: 600;font-size: 16px; color: #000;" v-if="!loading">
                <p><i class="mdi mdi-magnify mdi-24px" style="position: relative; top: 4px; margin-right: 16px;"></i> Brak wyników wyszukiwania.</p>
              </div>
            </section>
          </template>            
        </b-table> -->
      </div>
    </div>
    <custom-modal v-model="showTableModal">
      <template v-slot:title>Dane wyświetlane w tabeli</template>
      <div style="margin-top: 16px">
        <div
          v-for="column in columns.filter((item) => item.canHide)"
          :key="column.key"
        >
          <b-checkbox v-model="checkboxGroup" :native-value="column.key">
            {{ column.title }}
          </b-checkbox>
        </div>
      </div>
      <template v-slot:actions>
        <div class="button is-black is-medium" @click="changeTableColumns">
          Ustaw
        </div>
      </template>
    </custom-modal>
    <custom-modal v-model="showSendModal" @closed="emptyModalTrigger">
      <template v-slot:title>Powiadomienie</template>
      <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
        <ValidationObserver
          v-slot="{ handleSubmit, errors }"
          ref="emailSendObserver"
        >
          <form @submit.prevent="handleSubmit(onEmailSendSubmit)">
            <div
              class="field"
              :class="
                errors['subject'] && errors['subject'].length > 0
                  ? 'v-field v-error'
                  : ''
              "
            >
              <div class="ym-label">
                <label for="f_company_short">Temat</label>
              </div>
              <div class="control is-medium">
                <ValidationProvider rules="required" name="subject">
                  <input
                    name="subject"
                    v-model="mail.subject"
                    class="input is-medium"
                  />
                </ValidationProvider>
                <span
                  v-if="errors['subject'] && errors['subject'].length > 0"
                  class="help is-danger"
                  >{{ errors["subject"][0] }}</span
                >
              </div>
            </div>
            <div
              class="field"
              :class="
                errors['content'] && errors['content'].length > 0
                  ? 'v-field v-error'
                  : ''
              "
            >
              <div class="ym-label">
                <label for="f_company_short">Wiadomość</label>
              </div>
              <div class="control is-medium">
                <ValidationProvider rules="required" name="content">
                  <textarea
                    name="content"
                    v-model="mail.content"
                    class="input is-medium"
                  />
                </ValidationProvider>
                <span
                  v-if="errors['content'] && errors['content'].length > 0"
                  class="help is-danger"
                  >{{ errors["content"][0] }}</span
                >
              </div>
            </div>
            <button class="button is-black is-medium">Wyślij</button>
          </form>
        </ValidationObserver>
      </div>
    </custom-modal>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import debounce from "@/utils/debounce";
import YMmodal from "@/components/Modal.vue";
import CustomModal from "@/components/CustomModal";
import { format } from "date-fns";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Machinery",
  components: {
    FixedHeader,
    CustomModal,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      mail: {
        subject: "",
        content: "",
      },
      showSendModal: false,
      machineries: [],
      loading: false,
      total: 0,
      sortField: "name",
      sortOrder: "asc",
      defaultSortOrder: "asc",
      page: 1,
      perPage: 50,
      searchQuery: "",
      searchDeleted: 0,
      selected: null,
      eventCustomOption: {
        bodyCellEvents: ({ row, column }) => {
          return {
            click: () => {
              const exceptions = ["action_buttons", "checkbox", "order"];
              if (!exceptions.includes(column.field)) {
                this.details(row);
              }
            },
          };
        },
      },
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: true,
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      cellStyleOption: {
        bodyCellClass: ({ row }) => {
          if (
            row.technical_id === 45 ||
            row.technical_id === 48 ||
            row.operator_id === 49
          ) {
            return "is-info";
          } else if (row.service_type_id !== 0) {
            return `service-type service-type-${row.service_type_id}`;
          } else if (row.priority_id > 0) {
            return `priority priority-type${row.priority_id}`;
          }
        },
      },
      checkboxOption: {
        selectedRowChange: ({ selectedRowKeys }) => {
          // console.log(row, isSelected, selectedRowKeys);
          this.selectedRowKeys = selectedRowKeys;
        },
        selectedAllChange: ({ selectedRowKeys }) => {
          // console.log(isSelected, selectedRowKeys);
          this.selectedRowKeys = selectedRowKeys;
        },
      },
      sortOption: {
        sortChange: (params) => {
          let numberOfEmpty = 0;
          // console.log("sortChange::", params);
          for (const param in params) {
            if (params[param]) {
              this.sortChange({ key: param, value: params[param] });
            } else {
              numberOfEmpty += 1;
            }
          }
          if (Object.keys(params).length === numberOfEmpty) this.sortChange();
          // console.log(numberOfEmpty)
        },
      },
      columnHiddenOption: {
        defaultHiddenColumnKeys: [],
      },
      rawTableColumns: {
        default: [],
        dedicated: [],
      },
      selectedRowKeys: [],
      checkboxGroup: JSON.parse(
        localStorage.getItem("sapp-machinery-table")
      ) || ["name", "type", "serial"],
      showTableModal: false,
    };
  },
  watch: {
    searchQuery() {
      this.debouncedSearchQuery();
    },
  },
  mounted() {
    this.$refs.searchInput.$el.children[0].focus();
  },
  created() {
    this.debouncedSearchQuery = debounce(this.getMachineries, 500);
    this.getMachineries();
    document.addEventListener("keyup", this.tableNav);
    Array.from(document.querySelectorAll("table tbody tr")).forEach((node) => {
      node.classList.add("normal");
    });
  },
  destroyed() {
    document.removeEventListener("keyup", this.tableNav);
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      order: "orders/order",
      customer: "orders/customer",
      dictionary: "dictionary",
    }),
    customer_id() {
      return this.customer.id || null;
    },
    trows() {
      return document.querySelector("table").rows;
    },
    columns() {
      function getDateFormat(field) {
        switch (field) {
          case "datepicker":
            return "yyyy-MM-dd";
          case "datetimepicker":
            return "yyyy-MM-dd HH:mm";
          case "timepicker":
            return "HH:mm";
          default:
            return "yyyy-MM-dd";
        }
      }
      let columns = [
        {
          field: "checkbox",
          key: "a",
          type: "checkbox",
          title: "",
          width: 50,
          fixed: "left",
          align: "center",
          canHide: false,
        },
        {
          field: "action_buttons",
          key: "action_buttons",
          title: "",
          width: "20%",
          align: "right",
          fixed: "right",
          canHide: false,
          renderBodyCell: ({ row }) => {
            let tpl;
            let payload = { setDevice: row };
            if (row.is_active === true && this.selectADevice === false) {
              tpl = (
                <div class="buttons-wrap">
                  <span
                    class="button is-small40 w40"
                    on-click={() => this.triggerSendModal(row)}
                  >
                    <icon name="mail"></icon>
                  </span>
                  {Object.keys(row.order).length > 0 ? (
                    <span
                      class="button is-small40"
                      on-click={() => this.goToOrder(row)}
                    >
                      przejdź do zlecenia
                    </span>
                  ) : (
                    ""
                  )}
                  <span
                    class="button is-primary"
                    on-click={() => this.makeOrderFrom(payload)}
                  >
                    <div>
                      Dodaj zlecenie <icon name="plus"></icon>
                    </div>
                  </span>
                  <span
                    class="button is-small40"
                    on-click={() => this.machineryRemove(row.id)}
                  >
                    <div>
                      <icon name="trash"></icon>
                    </div>
                  </span>
                </div>
              );
            }
            if (this.selectADevice === true)
              tpl = (
                <a
                  class="button is-small40"
                  on-click={() => this.goTodetails(row)}
                >
                  więcej
                </a>
              );
            return tpl;
          },
        },
      ];
      if (this.rawTableColumns?.default) {
        const showEllipsis = [
          "technical_notes",
          "technical_service",
          "service_fault",
          "service_description",
          "device_service_work",
        ];
        const showYesOrNo = ["is_fv", "is_paid", "is_warranty", "is_trade"];
        for (let item of this.rawTableColumns.default) {
          let column = {
            field: item.field_name,
            key: item.field_name,
            title: item.name,
            sortBy: "",
            canHide: true,
          };
          if (showEllipsis.includes(item.field_name)) {
            column["ellipsis"] = { showTitle: true, lineClamp: 2 };
          }
          if (showYesOrNo.includes(item.field_name)) {
            const renderBodyCell = ({ row, column }) => {
              const cellData = row[column.field];
              return (
                <span class={cellData ? "text-green" : ""}>
                  {cellData ? "TAK" : "NIE"}
                </span>
              );
            };
            column["renderBodyCell"] = renderBodyCell;
          }
          if (item.field_name === "fullname") {
            const renderBodyCell = ({ row }) => {
              const text = row.firstname + " " + row.lastname;
              return <span>{text}</span>;
            };
            column["renderBodyCell"] = renderBodyCell;
          }

          columns.splice(columns.length - 1, 0, column);
        }
      }
      if (this.rawTableColumns?.dedicated) {
        const showEllipsis = ["address", "nip", "devices"];
        const dateTypeField = ["datepicker", "datetimepicker", "timepicker"];
        for (let item of this.rawTableColumns.dedicated) {
          let column = {
            field: item.field_name,
            key: item.field_name,
            title: item.name,
            sortBy: "",
            canHide: true,
            renderBodyCell: ({ row, column }) => {
              const cellData = row[column.field];

              let tpl;
              if (dateTypeField.includes(item.field_type)) {
                const result = cellData
                  ? format(new Date(cellData), getDateFormat(item.field_type))
                  : "-";
                tpl = result;
              } else if (item.field_name === "order") {
                const date =
                  cellData && cellData.created_at
                    ? format(new Date(cellData.created_at), "yyyy-MM-dd HH:mm")
                    : "";
                // <span class="button is-small40" on-click={() => this.goToOrder(row)}>przejdź do zlecenia</span>
                tpl = "";
                if (cellData.id)
                  tpl = (
                    <span class="order-date-wrap">
                      <span class="order-date">{date}</span>
                    </span>
                  );
              } else if (item.field_name === "order_client") {
                const order = row["order"];
                const client =
                  order && Object.keys(order).length > 0
                    ? order.customer_firstname + " " + order.customer_lastname
                    : "";
                // <span class="button is-small40" on-click={() => this.goToOrder(row)}>przejdź do zlecenia</span>
                tpl = <span>{client}</span>;
              } else if (item.field_name === "order_status") {
                const order = row["order"];
                const client =
                  order && Object.keys(order).length > 0
                    ? order.status.name
                    : "";
                // <span class="button is-small40" on-click={() => this.goToOrder(row)}>przejdź do zlecenia</span>
                if (order && Object.keys(order).length > 0) {
                  tpl = (
                    <span style={this.statusColor(order.status.id)}>
                      {client}
                    </span>
                  );
                } else {
                  tpl = <span>{client}</span>;
                }
              } else {
                tpl = cellData;
              }
              return tpl;
            },
          };
          if (showEllipsis.includes(item.field_name)) {
            column["ellipsis"] = { showTitle: true, lineClamp: 3 };
          }
          columns.splice(columns.length - 1, 0, column);
        }
      }
      const importOrder = ["name", "type", "serial"];
      const sortByObject = importOrder.reduce((obj, item, index) => {
        return {
          ...obj,
          [item]: index,
        };
      }, {});
      return columns.sort((a, b) => sortByObject[a.key] - sortByObject[b.key]);
      // return [
      //   { field: "name", key: "name", title: this.dictionary['table_order_device_name_field_label'], align: "left" },
      //   { field: "type", key: "type", title: this.dictionary['table_order_device_type_field_label'], align: "left" },
      //   { field: "serial", key: "serial", title: this.dictionary['table_order_device_serial_field_label'], align: "left" },
      //   { field: "action_buttons", key: "action_buttons", width: '20%', title: "", align: "right", fixed: "right", canHide: false, renderBodyCell: ({ row }) => {
      // let tpl;
      // if (row.is_active === true && this.selectADevice === false) {
      //   tpl = <div class="buttons-wrap">
      //     <span class="button is-primary" on-click={() => this.makeOrderFrom({'setDevice': row}) }><div>Dodaj zlecenie <icon name="plus"></icon></div></span>
      //     <span class="button is-small40" on-click={() => this.machineryRemove(row.id) }><div><icon name="trash"></icon></div></span>
      //   </div>
      // }
      // if ( this.selectADevice === true) tpl = <a class="button is-small40" on-click={() => this.goTodetails(row) }>więcej</a>
      // return tpl;
      //   }},
      // ]
    },
  },
  methods: {
    ...mapActions({
      makeOrderFrom: "orders/makeOrderFrom",
      getMachineriesCall: "records/getMachineries",
      removeMachineryCall: "records/removeMachinery",
      sentEmailCall: "orders/sendEmail",
    }),
    sortChange(params) {
      // console.log(params)
      if (
        (params &&
          [
            "customer_files",
            "date_of_purchase",
            "delivery_date",
            "dominant_color",
            "purchase_file",
            "repair_cost_agree",
            "repair_priority",
            "service_book",
            "time_of_purchase",
            "what_to_repair",
          ].includes(params.key)) ||
        (params && params.key.includes("input_"))
      ) {
        // console.log(params.key)
        this.machineries.sort((a, b) => {
          if (params === undefined) {
            return a.id > b.id ? -1 : 1;
          } else if (params.value === "desc") {
            return b["dedicated_form_fields"][params.key]
              ? a["dedicated_form_fields"][params.key]
                ? ("" + b["dedicated_form_fields"][params.key]).localeCompare(
                    a["dedicated_form_fields"][params.key]
                  )
                : 1
              : -1;
            /* return b[params.key] > a[params.key] ? 1 : -1; */
          } else if (params.value === "asc") {
            return a["dedicated_form_fields"][params.key]
              ? b["dedicated_form_fields"][params.key]
                ? ("" + a["dedicated_form_fields"][params.key]).localeCompare(
                    b["dedicated_form_fields"][params.key]
                  )
                : -1
              : 1;
            /* return a[params.key] < b[params.key] ? -1 : 1; */
          }
        });
      } else {
        this.machineries.sort((a, b) => {
          if (params === undefined) {
            return a.id > b.id ? -1 : 1;
          } else if (params.value === "desc") {
            return b[params.key]
              ? a[params.key]
                ? ("" + b[params.key]).localeCompare(a[params.key])
                : 1
              : -1;
            /* return b[params.key] > a[params.key] ? 1 : -1; */
          } else if (params.value === "asc") {
            return a[params.key]
              ? b[params.key]
                ? ("" + a[params.key]).localeCompare(b[params.key])
                : -1
              : 1;
            /* return a[params.key] < b[params.key] ? -1 : 1; */
          }
        });
      }
    },
    onPageSizeChange(pageSize) {
      this.perPage = pageSize;
      this.onPageChange(1);
    },
    getMachineries() {
      this.loading = true;
      const {
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        customer_id,
      } = this;
      this.getMachineriesCall({
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        customer_id,
      })
        .then((resp) => {
          this.total = resp.page.items;
          this.machineries = resp.data;
          if (this.total > 0) {
            const data = this.machineries[0];
            const name_helper = {
              id: "ID",
              name: this.dictionary["table_order_device_name_field_label"],
              type: this.dictionary["table_order_device_type_field_label"],
              serial: this.dictionary["table_order_device_serial_field_label"],
              order:
                this.dictionary["table_order_device_order_date_field_label"] ??
                "Data ostatniego zlecenia",
              order_client:
                this.dictionary[
                  "table_order_device_order_client_field_label"
                ] ?? "Klient ostatniego zlecenia",
              order_status:
                this.dictionary[
                  "table_order_device_order_status_field_label"
                ] ?? "Status ostatniego zlecenia",
            };
            for (const [key, value] of Object.entries(data)) {
              const excluded = ["deleted_at", "is_active", "created_at"];
              if (!excluded.includes(key)) {
                this.rawTableColumns["dedicated"].push({
                  field_name: key,
                  field_type: typeof value,
                  name: name_helper[key],
                });
              }
            }
            this.rawTableColumns["dedicated"].push({
              field_name: "order_client",
              field_type: "string",
              name: name_helper["order_client"],
            });
            this.rawTableColumns["dedicated"].push({
              field_name: "order_status",
              field_type: "string",
              name: name_helper["order_status"],
            });
            // this.checkboxGroup = ['fullname', 'name', 'nip', 'phone', 'email']
            const allColumns = this.columns
              .filter((item) => item.canHide)
              .map((a) => a.key);
            let difference = allColumns
              .filter((x) => !this.checkboxGroup.includes(x))
              .concat(
                this.checkboxGroup.filter((x) => !allColumns.includes(x))
              );
            this.showColumns(allColumns);
            this.hideColumns(difference);
            // this.hideColumns(difference)
          }
        })
        .catch((error) => {
          this.machineries = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    machineryRemove(id) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć?",
          content: `<span class='is-danger'>Usunięcia nie można cofnąć.</span> Pozostanie w utworzonych do tej pory zleceniach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeMachineryCall({ id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getMachineries();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    onPageChange(page) {
      this.page = page;
      if (this.searchQuery === "" || this.searchQuery === null) {
        this.getMachineries();
      }
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getMachineries();
    },
    tableNav($event) {
      var trows = this.trows,
        trow,
        nextrow;

      switch ($event.keyCode) {
        case 13: {
          return active();
        }
        case 38: {
          return movehighlight(-1, $event);
        }
        case 40: {
          return movehighlight(1, $event);
        }
        default: {
          return true;
        }
      }

      function active() {
        document.querySelector("table tbody tr.is-selected a").click();
      }

      function movehighlight(way, e) {
        e.preventDefault && e.preventDefault();
        e.returnValue = false;
        var idx = highlightRow(true); //gets current index or null if none highlighted

        if (typeof idx === "number") {
          //there was a highlighted row
          idx += way; //increment\decrement the index value
          if (idx && (nextrow = trows[idx])) {
            return highlightRow.apply(nextrow);
          } //index is > 0 and a row exists at that index
          else if (idx) {
            return highlightRow.apply(trows[1]);
          } //index is out of range high, go to first row
          return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
        }
        return highlightRow.apply(trows[way > 0 ? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow
      }

      function highlightRow(gethighlight) {
        //now dual use - either set or get the highlighted row
        gethighlight = gethighlight === true;
        var t = trows.length;
        while (--t > -1) {
          trow = trows[t];
          if (gethighlight && trow.className === "is-selected") {
            return t;
          } else if (!gethighlight && trow !== this) {
            trow.className = "normal";
          }
        } //end while

        return gethighlight
          ? null
          : (this.className =
              this.className === "is-selected" ? "normal" : "is-selected");
      }
    },
    toggleSearchDeleted() {
      this.debouncedSearchQuery();
    },
    goToOrder(device) {
      this.$router.push({
        name: "ServiceDetails",
        params: { id: device.order.id },
      });
    },
    exit() {
      this.$store.dispatch("orders/setSelectADevice", false).then(() => {
        this.$router.push({ name: "ServiceAdd" });
      });
    },
    details(device) {
      if (this.selectADevice) {
        this.$store.dispatch("orders/setDevice", device).then(() => {
          if (this.order && this.order.id) {
            this.$router.push({
              name: "ServiceEdit",
              params: { id: parseInt(this.order.id) },
            });
          } else {
            this.$router.push({ name: "ServiceAdd" });
          }
        });
      } else {
        this.$router.push({
          name: "MachineryDetails",
          params: { id: device.id },
        });
      }
    },
    goTodetails(device) {
      this.$router.push({
        name: "MachineryDetails",
        params: { id: device.id },
      });
    },
    changeTableColumns() {
      const allColumns = this.columns
        .filter((item) => item.canHide)
        .map((a) => a.key);
      let difference = allColumns
        .filter((x) => !this.checkboxGroup.includes(x))
        .concat(this.checkboxGroup.filter((x) => !allColumns.includes(x)));
      this.showColumns(allColumns);
      this.hideColumns(difference);
      localStorage.setItem(
        "sapp-machinery-table",
        JSON.stringify(allColumns.filter((x) => this.checkboxGroup.includes(x)))
      );
      this.showTableModal = false;
    },
    hideColumns(keys) {
      this.$refs["tableRef"].hideColumnsByKeys(keys);
    },
    showColumns(keys) {
      this.$refs["tableRef"].showColumnsByKeys(keys);
    },
    triggerSendModal(row) {
      this.selectedRowKeys.push(row.id);
      this.showSendModal = true;
    },
    onEmailSendSubmit() {
      const { subject, content } = this.mail;
      const emails = [];
      for (let device of this.machineries) {
        if (
          Object.keys(device.order).length > 0 &&
          this.selectedRowKeys.includes(device.id)
        )
          emails.push(device.order.customer_email);
      }
      this.sentEmailCall({ subject, content, emails }).then(() => {
        this.showSendModal = false;
        this.selectedRowKeys = [];
      });
    },
    emptyModalTrigger() {
      this.selectedRowKeys = [];
      this.mail = {
        subject: "",
        content: "",
      };
      this.$nextTick(() => {
        this.$refs.emailSendObserver.reset();
      });
    },
    statusColor: function (id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  justify-content: flex-end;
}
.order-date {
  font-weight: bold;
}
</style>
