<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/machinery"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/machinery"><a>{{ dictionary['table_order_devices_type_field_label'] }}</a></router-link>
          <router-link tag="li" to="/records/machinery/add" class="is-active"><a>Dodaj</a></router-link>
        </ul>   
      </nav>
    </portal-target>

    <MachineryForm />
  </section>
</template>

<script>
  import MachineryForm from '@/views/Records/Machinery/MachineryForm'
  import {mapGetters} from 'vuex'

  export default {
    name: 'MachineryAdd',
    components: {
      MachineryForm
    },
    computed:{
      ...mapGetters({
        dictionary: 'dictionary',
      }), 
    }
  }
</script>

<style lang="scss" scoped>

</style>